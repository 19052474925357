
<script setup lang="ts">
    import { ref, watch, computed, onMounted, onBeforeUnmount } from "vue";
    import { useZIndex } from "./ZIndex.ts";

    export interface IProps {
        show?: boolean;
        minHeight?: any;
        maxHeight?: any;
        fullscreen?: boolean;
        halfscreen?: boolean;
        teleportTarget?: any;
        keepAlive?: boolean;
        title?: string;
        height?: number;
        size?: "sm" | "md" | "lg";
    };

    export interface IEmits {
        (e: "show"): void;
        (e: "hide"): void;
    };

    const props = defineProps<IProps>();
    const emit = defineEmits<IEmits>();
    const model = defineModel<boolean>();

    function getSize(): string | undefined {
        if (props.size === "sm") {
            return "calc(40% - 4rem)";
        } else if (props.size === "md") {
            return "calc(80% - 4rem)";
        } else if (props.size === "lg") {
            return "calc(100% - 4rem)";
        }
        // TODO: remove
        if (props.fullscreen != null || props.minHeight === "max") {
            return "calc(100% - 4rem)";
        }
        if (props.halfscreen != null) {
            return "calc(70% - 4rem)";
        }
    }

    const minHeight = computed(() => {
        const size = getSize();
        return size ?? props.minHeight ?? "auto";
    });

    const maxHeight = computed(() => {
        const size = getSize();
        return size ?? props.maxHeight ?? "calc(100% - 4rem)";
    });

    const modal = ref(null);
    const animating = ref(false);
    const showBackdrop = ref(false);

    watch(() => model.value, (val) => {
        if (val) {
            setTimeout(() => {
                showBackdrop.value = val;
            });
        } else {
            showBackdrop.value = val;
        }
    });

    const backdrop_zindex = useZIndex(() => model.value);
    const modal_zindex = useZIndex(() => model.value);


    const state = {
        start_x: 0,
        start_y: 0,
        vel_x: 0,
        vel_y: 0,
        x: 0,
        y: 0,
        cancel: false,
        prevent: false,
        velocity: [],
    };

    watch(() => model.value, () => {
        if (model.value) {
            // show modal
            // if (modal.value) {
                // modal.value.style.transition = "all 300ms ease-in-out";
                // modal.value.style.transform = "translate(0%, 0%)";
            // }
            // emit("show");
            /* cool idea to mimic fullscreen bottom sheets from iOS, needs some fine-tuning and consideration */
            /*
            document.getElementById("app").style.inset = "0.5rem";
            document.getElementById("app").style.borderRadius = "0.5rem";
            document.getElementById("app").style.fontSize = "0.9em";
            */
        } else {
            // hide modal
            if (modal.value) {
                modal.value.style.transition = "all 200ms ease-in-out";
                modal.value.style.transform = "translate(0%, 100%)";
                animating.value = true;
            }
            // emit("hide");
            /*
            document.getElementById("app").style.inset = "0";
            document.getElementById("app").style.borderRadius = "0";
            document.getElementById("app").style.fontSize = "1em";
            */
        }
    });

    function onTouchStart(e) {
        state.prev_x = e.touches[0].clientX;
        state.prev_y = e.touches[0].clientY;
        state.prev_t = e.timeStamp;
        state.vel_x = 0;
        state.vel_y = 0;
        state.cancel = false;
        state.prevent = false;
        state.firstFrame = true;

        if (!model.value) {
            state.cancel = true;
        }
    }

    function onTouchMove(e) {
        if (!model.value) {
            state.cancel = true;
        }
        
        // precalculate
        const target = e.target.closest(".scrollable") || e.target;

        const dif_x = e.touches[0].clientX - state.prev_x;
        const dif_y = e.touches[0].clientY - state.prev_y;

        // always cancel if prevent-swipe
        if (e.target.closest(".prevent-swipe")) {
            state.cancel = true;
        }

        // on first frame, check if we are allowed to swipe based on scroll
        if (state.firstFrame) {
            // vertical scrolling
            // if (Math.abs(dif_y) > Math.abs(dif_x) && e.target.closest(".mover-stop-vertical")) {
                // state.cancel = true;
            // }
            //if (Math.abs(dif_y) > Math.abs(dif_x)) {

            // if (e.target.closest(".prevent-swipe")) {
                // state.cancel = true;
            // }

            // check if vertical scrolling is available
            if (target.scrollHeight > target.clientHeight) {
                if (dif_y >= 0) {
                    // when scrolling up, cancel swiping if scroll is not at the top
                    if (target.scrollTop !== 0) {
                        state.cancel = true;
                    }
                } else {
                    // when scrolling down, cancel swiping if scroll is not at the bottom
                    if (target.scrollTop !== (target.scrollHeight - target.clientHeight)) {
                        state.cancel = true;
                    }
                }
            }

            // check if horizontal scrolling is available
            if (target.scrollWidth > target.clientWidth) {
                // cancel bottomsheet if scrolling horizontally
                if (Math.abs(dif_x) > Math.abs(dif_y)) {
                    state.cancel = true;
                }
                // if (dif_x >= 0) {
                    // if (target.scrollLeft !== 0) {
                        // state.cancel = true;
                    // }
                // } else {
                    // if (target.scrollLeft !== (target.scrollWidth - target.clientWidth)) {
                        // state.cancel = true;
                    // }
                // }
            }

            //} else {
                /*
                if (dif_x >= 0) {
                    if (target.scrollLeft !== 0) {
                        state.cancel = true;
                    }
                } else {
                    if (target.scrollLeft !== (target.scrollWidth - target.clientWidth)) {
                        state.cancel = true;
                    }
                }
                */
            //}
        }

        // either cancel swiping to allow for scroll, or prevent scrolling to allow for swipe
        if (state.cancel) {
            return;
        } else {
            e.preventDefault();
        }

        // update state
        state.x += dif_x;
        state.y += dif_y;

        state.vel_y = dif_y/(e.timeStamp - state.prev_t);

        state.prev_x = e.touches[0].clientX;
        state.prev_y = e.touches[0].clientY;
        state.prev_t = e.timeStamp;

        // update visual
        let offset_y = state.y;
        if (offset_y < 0) {
            offset_y *= -1;
            offset_y **= 0.75;
            offset_y *= -1;
        }

        modal.value.style.transition = "all 0ms ease-in-out";
        modal.value.style.transform = `translate(0%, ${offset_y}px)`;

        state.firstFrame = false;
    }

    function onTouchEnd(e) {
        if (state.cancel) {
            return;
        }

        if (state.vel_y > 0.5 || (state.vel_y >= 0 && state.y > modal.value.clientHeight/2)) {
            model.value = false;
        } else {
            modal.value.style.transition = "all 400ms ease-in-out";
            modal.value.style.transform = "translate(0%, 0%)";
        }

        state.x = 0;
        state.y = 0;
    }

    function onAnimStart(e) {
        animating.value = true;
    }

    function onAnimEnd(e) {
        animating.value = false;
    }

    watch(modal, (val, old) => {
        if (val) {
            modal.value.style.transition = "all 0ms";
            modal.value.style.transform = "translate(0%, 100%)";
            setTimeout(() => {
                modal.value.style.transition = "all 300ms ease-in-out";
                // modal.value.style.transition = "all 500ms cubic-bezier(0.34, 1.56, 0.64, 1)";
                modal.value.style.transform = "translate(0%, 0%)";
            });
            val.addEventListener("touchstart", onTouchStart);
            val.addEventListener("touchmove", onTouchMove);
            val.addEventListener("touchend", onTouchEnd);
            val.addEventListener("transitionstart", onAnimStart);
            val.addEventListener("transitionend", onAnimEnd);
            emit("show");
        } else {
            old.removeEventListener("touchstart", onTouchStart);
            old.removeEventListener("touchmove", onTouchMove);
            old.removeEventListener("touchend", onTouchEnd);
            old.removeEventListener("transitionstart", onAnimStart);
            old.removeEventListener("transitionend", onAnimEnd);
            emit("hide");
        }
    });

    onMounted(() => {
        if (modal.value) {
            // modal.value.addEventListener("touchstart", onTouchStart);
            // modal.value.addEventListener("touchmove", onTouchMove);
            // modal.value.addEventListener("touchend", onTouchEnd);

            // modal.value.addEventListener("transitionstart", onAnimStart);
            // modal.value.addEventListener("transitionend", onAnimEnd);
        }
    });

    onBeforeUnmount(() => {
        if (modal.value) {
            // modal.value.removeEventListener("touchstart", onTouchStart);
            // modal.value.removeEventListener("touchmove", onTouchMove);
            // modal.value.removeEventListener("touchend", onTouchEnd);

            // modal.value.removeEventListener("transitionstart", onAnimStart);
            // modal.value.removeEventListener("transitionend", onAnimEnd);
        }
    });
</script>

<template>
    <template v-if="model || animating || keepAlive">
        <div style="position: absolute;">
            <teleport :to="teleportTarget || 'body'" >
                <div style="position: absolute;">
                    <!-- backdrop -->
                    <div
                        class="c-bottom-sheet-backdrop"
                        :class="{ 'show': showBackdrop }"
                        style="position: fixed; inset: 0; background-color: rgba(0%, 0%, 0%, 40%);"
                        :style="{ 'z-index': backdrop_zindex }"
                        @click="model = false"
                    >
                    </div>

                    <!-- modal -->
                    <div
                        ref="modal"
                        class="c-bottom-sheet-modal d-flex flex-column"
                        :class="{ 'show': model, 'c-bottom-sheet-reset': !model }"
                        style="position: fixed; left: 0; right: 0; bottom: 0; border-radius: 1rem 1rem 0 0; background-color: white; pointer-events: auto;"
                        :style="{ 'z-index': modal_zindex, 'min-height': minHeight, 'max-height': maxHeight }"
                    >
                        <slot name="header">
                            <div class="flex-shrink-0 d-flex" style="position: relative; height: 4rem;">
                                <!-- title -->
                                <div class="d-flex justify-content-center align-items-center" style="position: absolute; inset: 0; font-size: 1.25em; font-weight: 500;">
                                    <slot name="title">{{ title ?? $t("Title") }}</slot>
                                </div>

                                <!-- action -->
                                <div class="d-flex align-items-center" style="position: absolute; top: 0; left: 0; bottom: 0;">
                                    <slot name="action" />
                                </div>

                                <a
                                    class="d-flex justify-content-center align-items-center"
                                    style="position: absolute; top: 0; right: 0; bottom: 0; aspect-ratio: 1; text-decoration: none; color: rgb(60%, 60%, 60%);"
                                    @click="model = false"
                                >
                                    <i class="bi bi-x" style="font-size: 1.5em;" />
                                </a>
                            </div>
                        </slot>
                        
                        <!-- subheader -->
                        <slot name="subheader">
                        </slot>

                        <!-- body -->
                        <div class="flex-grow-1 scrollable d-flex flex-column" style="margin-top: -0.75rem; position: relative; overflow-y: auto; overscroll-behavior: none;">
                            <slot name="body" />
                            <slot />
                        </div>

                        <!-- drag handle -->
                        <div style="position: absolute; top: 6px; left: 50%; transform: translate(-50%, 0%); width: 4rem; height: 4px; background-color: rgb(80%, 80%, 80%); border-radius: 999px;">
                        </div>
                    </div>
                </div>
            </teleport>
        </div>
    </template>
</template>

<style scoped>
    .c-bottom-sheet-backdrop {
        transition: all 300ms ease-in-out;

        pointer-events: none;
        opacity: 0%;

        &.show {
            pointer-events: auto;
            opacity: 100%;
        }
    }

    .c-bottom-sheet-modal {
        transition: all 400ms ease-in-out;

        box-shadow: none;
        transform: translate(0%, 100%);

        &.show {
            box-shadow: 0 0 16px 0 rgba(0%, 0%, 0%, 15%);
            transform: translate(0%, 0%);
        }
    }

    .c-bottom-sheet-reset {
        transition: all 400ms ease-in-out;
        transform: translate(0, 100%);
    }
</style>
